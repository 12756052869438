<template>
  <div>
    <b-overlay :show="loading">
      <filtros-reporte-teletutoria
        report-type="teletutoria"
        @buscar="buscar"
      />
      <b-card>
        <b-card-body>
          <h4 class="card-title">
            Listado
          </h4>
          <div>
            <b-row>
              <!-- {{ notificaciones }}-->
              <b-col
                sm="12"
                md="4"
                class="my-1"
              >
                <div class="d-flex align-items-center mb-1 mb-md-0">
                  <label>Mostrar</label>
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    class="mx-50 col-md-2"
                    @change="onPerPageChange"
                  />
                  <label>filas por página</label>
                </div>
              </b-col>
              <b-col
                md="1"
                class="my-1"
              />
              <b-col
                md="3"
                sm="4"
                class="my-1"
              />
              <b-col
                sm="4"
                md="4"
                class="my-1"
              >
                <div class="d-flex justify-content-end ">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    class="ml-1"
                    @click="exportar"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Exportar</span>

                  </b-button>
                </div>
              </b-col>

              <b-col cols="12">
                <b-table
                  striped
                  hover
                  small
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  responsive
                  class="mb-0"
                  show-empty
                >
                  <template #empty="scope">
                    <div class="text-center">
                      No existen resultados
                    </div>
                  </template>
                </b-table>
              </b-col>

            </b-row>
            <b-row class="pl-1">
              <b-col
                cols="8"
                class="mt-3"
              >
                <div v-if="totalRows > 0">
                  <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
                </div>
              </b-col>

              <b-col cols="4">
                <b-pagination
                  v-model="page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  class="mt-3 mr-1"
                  aria-controls="my-table"
                  @change="onPageChange"
                />
              </b-col>

            </b-row>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal
        ref="modalExport"
        title="Información"
        ok-only
        ok-title="Cerrar"
    >
      <p class="my-4">
        <b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe
      </p>
    </b-modal>
  </div>

</template>

<script>

import {
  BButton, BButtonToolbar, ButtonGroupPlugin, VBTooltip, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import es from 'vee-validate/dist/locale/es.json'
import FiltrosReportesTeletutoria from '@/components/FiltrosReporteSeguimientos'
import store from '@/store'

export default {
  name: 'DescuentoProductos',
  components: {
    BButtonToolbar,
    ButtonGroupPlugin,
    BButton,
    BTooltip,
    vSelect,
    'filtros-reporte-teletutoria': FiltrosReportesTeletutoria,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {

  },
  data() {
    return {
      perPage: 50,
      pageOptions: [50, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      ver: false,
      from: 1,
      to: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      page: 1,
      filterOn: [],
      count: 0,
      listaReporteSegumiento: [],
      disabled: 0,
      usuarioLogeado: null,
      admin: false,
      notificacion: '',
      fields: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'rut_alumno', label: 'Rut' },
        { key: 'nombre_alumno', label: 'Nombre' },
        { key: 'apellido_alumno', label: 'Apellidos' },
        { key: 'num_ficha', label: 'Num Ficha' },
        { key: 'nombre_holding', label: 'Holding' },
        { key: 'empresa', label: 'Empresa' },
        { key: 'fecha_inicio', label: 'Fecha Inicio' },
        { key: 'fecha_fin', label: 'Fecha Fin' },
        { key: 'contact_asignado', label: 'Teletutor' },
        { key: 'contact_seguimiento', label: 'Seguimiento' },
        { key: 'curso', label: 'Curso' },
        { key: 'modalidad', label: 'Modalidad' },
        { key: 'platform_name', label: 'Plataforma' },
        { key: 'cat4', label: 'Cat 4' },
        { key: 'cat3', label: 'Cat 3' },
        { key: 'cat2', label: 'Cat 2' },
        { key: 'cat1', label: 'Cat 1' },
      ],
      filter: {
        selectedHolding: '',
        selectedEmpresa: '',
        selectedNumeroficha: '',
        selectedTeletutor: '',
        selectedDias: '',
      },
    }
  },
  computed: {
  /*  notificaciones() {
      this.notificacion = this.$store.state.teletutoria.notificaciones
      if (this.notificacion.titulo !== '') {
        this.makeToast('success', this.notificacion.titulo, this.notificacion.mensaje)
        this.notificacion.titulo = ''
        this.selectedTeletutorAsignar = ''
        this.filter.selectedAsignado = ''
        this.getListadoTeletutoria()
      }
    }, */
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    items() {
      this.totalRows = this.$store.state.reporteSeguimientos.rowsTotal
      this.listaReporteSegumiento = this.$store.state.reporteSeguimientos.reporteSeguimientosLista
      this.currentPage = this.$store.state.reporteSeguimientos.currentPage
      this.to = this.$store.state.reporteSeguimientos.to
      this.from = this.$store.state.reporteSeguimientos.from
      if (this.listaReporteSegumiento !== undefined) {
        return this.listaReporteSegumiento.map(item => ({
          // id_detalle_alumno: item.id_detalle_alumno,
          rut_alumno: item.rut_alumno,
          nombre_alumno: item.nombre_alumno,
          apellido_alumno: item.apellido_alumno,
          num_ficha: item.num_ficha,
          nombre_holding: item.nombre_holding,
          empresa: item.empresa,
          platform_name: item.platform_name,
          fecha_inicio: item.fecha_inicio,
          fecha_fin: item.fecha_fin,
          contact_asignado: item.contact_asignado,
          contact_seguimiento: item.contact_seguimiento,
          modalidad: item.modalidad,
          curso: item.curso,
          cat4: item.cat4,
          cat3: item.cat3,
          cat2: item.cat2,
          cat1: item.cat1,
        }))
      }
    },
    // eslint-disable-next-line vue/no-dupe-keys
    loading() {
      return this.$store.state.reporteSeguimientos.loading
    },

  },
  watch: {

  },
  mounted() {
    this.$store.dispatch('reporteSeguimientos/getOptionDias')
    this.usuarioLogeado = store.state.auth.user
    console.log(this.usuarioLogeado)
    if (this.usuarioLogeado.fk_id_perfil == 39) {
      this.filter.selectedTeletutor = this.usuarioLogeado.id_usuario
    }
    this.getListadoReporteSeguimiento()
  },
  methods: {
    exportar() {
      if (this.listaReporteSegumiento.length == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'warning',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const fieldsV = []
      Object.entries(this.fields).forEach(entry => {
        const [key, value] = entry
        if (value.display) {
          fieldsV.push(key)
        }
      })

      this.filter.fields = fieldsV
      const filterData = {
        id_empresa: this.filter.selectedEmpresa,
        id_holding: this.filter.selectedHolding,
        num_ficha: this.filter.selectedNumeroficha,
        id_usuario: this.filter.selectedTeletutor,
        id_dias: this.filter.selectedDias,
        page: this.page,
        perPage: this.perPage,
      }
      if (this.totalRows >= 50000) {

        this.$refs.modalExport.show()

        this.$store.dispatch('reporteSeguimientos/exportar', filterData)
      } else {
        this.$store.dispatch('reporteSeguimientos/exportarMenor5000', filterData)
      }
    },
    getListadoReporteSeguimiento() {
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        id_empresa: this.filter.selectedEmpresa,
        id_holding: this.filter.selectedHolding,
        num_ficha: this.filter.selectedNumeroficha,
        id_usuario: this.filter.selectedTeletutor,
        id_dias: this.filter.selectedDias,
      }
      this.$store.dispatch('reporteSeguimientos/getListadoReporteSeguimiento', filterData)
    },
    buscar(filter) {
      filter.selectedEmpresa == null ? this.filter.selectedEmpresa = '' : this.filter.selectedEmpresa = filter.selectedEmpresa
      filter.selectedHolding == null ? this.filter.selectedHolding = '' : this.filter.selectedHolding = filter.selectedHolding
      filter.selectedNumeroficha == null ? this.filter.selectedNumeroficha = '' : this.filter.selectedNumeroficha = filter.selectedNumeroficha
      filter.selectedTeletutor == null ? this.filter.selectedTeletutor = '' : this.filter.selectedTeletutor = filter.selectedTeletutor
      filter.selectedDias == null ? this.filter.selectedDias = '' : this.filter.selectedDias = filter.selectedDias
      this.page = 1
      this.getListadoReporteSeguimiento()
    },
    onPageChange(page) {
      this.page = page
      this.getListadoReporteSeguimiento()
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.getListadoReporteSeguimiento()
    },
    makeToast(variant = null, titulo, mensaje) {
      this.$bvToast.toast(mensaje, {
        // title: `Variant ${variant || 'default'}`,
        title: titulo,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style>

</style>
